import { endpoints } from './endpoints'
import request from '@/helpers/request'

export function companyStatisticsCards(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.companyStatisticsCards,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function companyStatisticsGraphs(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.companyStatisticsGraphs,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function companyStatisticsGeo(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.companyStatisticsGeo,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function exportComplaints(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.exportComplaints,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}