<template>
<main>
    <div class="datepickers">
    <div style="display: flex;align-items:center;">
        <label style="margin-bottom:0; font-weight: 600;">Desde: </label>
    <datetime
      width="100%"
      class="my-2 mx-4 datepickers__datepicker"
      type="date"
      v-model="start_date"
      :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
    ></datetime>
    </div>
    <div style="display: flex; align-items:center;">
        <label style="margin-bottom:0; font-weight: 600;">Hasta:</label>
    <datetime
      width="100%"
      class="my-2 mx-4 datepickers__datepicker"
      type="date"
      v-model="end_date"
      :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
    ></datetime>
    </div>
        <CButton
          class="mr-0"
          color="success"
          square
          :disabled="!start_date || !end_date"
          size="sm"
          @click="getMap()"
        >
          Obtener Mapa
        </CButton>
    </div>
  <div class="w-100 p-0 map-container-dashboard" style="position: relative">
    <GmapMap
      ref="map"
      :center="$store.state.mapCenter.center"
      :zoom="$store.state.mapCenter.zoom"
      :options="{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      }"
      class="map-container"
      :style="'height: ' + containerHeight + 'px'"
    >
      <GmapHeatmap v-if="showHeatMap" :data="heatData" :options="{ dissipating: false }" />
      <!--       <GmapMarker
        :key="'b' + index"
        :ref="'b' + index"
        v-for="(m, index) in clusterMarkers"
        :position="{
          lat: Number(m.location.latitude),
          lng: Number(m.location.longitude),
        }"
        :clickable="true"
        :icon="{
          url: returnIcon(m),
          scaledSize: { width: 40, height: 68 },
          markerInfo: m,
        }"
        @click="startMonitorFromAlert(m)"
        @mouseout="outCluster"
        @mouseover="inCluster"
      /> -->
      <!--      <GmapCluster
        ref="markercluster"
        :styles="clusterStyles"
        :averageCenter="true"
        :zoomOnClick="true"
        :ignoreHidden="true"
        @mouseout="outCluster"
        @mouseover="inCluster"
      > -->
      <GmapMarker
        :key="index"
        :ref="index"
        v-for="(m, index) in denuncias"
        :position="{
          lat: Number(m.location.latitude),
          lng: Number(m.location.longitude),
        }"
        :clickable="true"
        :icon="{
          url: returnIcon(m),
          scaledSize: { width: 40, height: 68 },
          markerInfo: { ...m },
        }"
        :title="m.type && m.type.title"
      >
      </GmapMarker>
      <!--    </GmapCluster> -->
    </GmapMap>

    <div style="position: absolute; top: 30px; left: 30px; z-index: 99">
      <CButton
        v-c-tooltip="'Acercar Mapa'"
        class="mx-1"
        color="success"
        @click="changeZoom()"
      >
        <font-awesome-icon icon="plus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Alejar Mapa'"
        class="mx-1"
        color="primary"
        @click="changeZoom(1)"
      >
        <font-awesome-icon icon="minus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Ver mapa calor alertas'"
        :color="showHeatMap ? 'success' : 'primary'"
        class="mx-1"
        @click="toggleHeatMap()"
      >
        <CSpinner
          style="width: 20px; height: 20px"
          color="white"
          v-if="loadingHeatMap"
        />
        <font-awesome-icon v-else icon="fire-alt" style="font-size: 20px" />
      </CButton>
    </div>
  </div>
  </main>
</template>

<script>
import GmapHeatmap from "../../widgets/Map/Heatmap";
import { gmapApi } from "vue2-google-maps";
import moment from "moment";


export default {
   props: ["denuncias", "getGeoStats"], 
  components: {
    GmapHeatmap,
  },
  data() {
    return {
      showHeatMap: false,
      containerHeight: 400,
      loadingHeatMap: false,
      start_date: null,
      end_date: null,
    };
  }, 
  computed: {
    google: gmapApi,
    heatData() {
      return  this.google && this.denuncias.map((marker) => {
          return {
            location: new this.google.maps.LatLng({
              lat: marker.location.latitude,
              lng: marker.location.longitude,
            }),
            weight: 2,
          };
        })
    },
  },
  methods: {
    returnIcon(marker) {
      if (marker.type && marker.type.base_type) {
        return marker.type.base_type.marker_icon;
      } else if (marker.type) {
        return marker.type.marker_icon;
      }
    },
    toggleHeatMap() {
      this.showHeatMap = !this.showHeatMap;
      if (this.showHeatMap) {
        this.loadingHeatMap = true;

        this.loadingHeatMap = false;
      }
    },
    changeZoom(type = 0) {
      this.$refs.map.$mapPromise.then((map) => {
        const actual = map.getZoom();
        type === 0 ? map.setZoom(actual + 1) : map.setZoom(actual - 1);
      });
    },
    getMap() {
        const data = {start_date: moment(this.start_date).format("YYYY-MM-DD"), end_date: moment(this.end_date).format("YYYY-MM-DD")}
        this.getGeoStats(data)
    }
  },
};
</script>

<style lang="scss" scoped>
    .datepickers{
        display: flex;
        justify-content: end;
        margin-bottom: 12px;
    }

    @media (max-width: 576px) {
        .datepickers{
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px auto;
        }
    }
</style>
