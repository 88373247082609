<template>
  <main style="margin-bottom: 100px;">
    <div class="datepickers">
    <div style="display: flex;align-items:center;">
        <label style="margin-bottom:0; font-weight: 600;">Desde: </label>
    <datetime
      width="100%"
      class="my-2 mx-4 datepickers__datepicker"
      type="date"
      v-model="start_date"
      :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
    ></datetime>
    </div>
    <div style="display: flex; align-items:center;">
        <label style="margin-bottom:0; font-weight: 600;">Hasta:</label>
    <datetime
      width="100%"
      class="my-2 mx-4 datepickers__datepicker"
      type="date"
      v-model="end_date"
      :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
    ></datetime>
    </div>
    <CButton
          color="success"
          square
          size="sm"
          @click="getExcel()"
        >
          Descargar xlsx
    </CButton>
    </div>

    <div class="Cards">
      <CardStats
        color="#0767C5"
        :data="users_last"
        title="Usuarios"
        :number="cardstats.users"
      />
      <CardStats
        color="#5C93D3"
        :data="complaints_last"
        title="Denuncias"
        :number="cardstats.complaints"
      />
      <CardStats
        color="#7042BD"
        :data="alerts_last"
        title="Alertas"
        :number="cardstats.alerts"
      />
      <CardStats
        color="#2B333E"
        :data="most_used_complaint_last"
        title="Tipo de denuncia más usada"
        :footerText="cardstats?.most_used_complaint_type?.title"
      />
    </div>

    <div class="Charts">
      <div class="Charts__MainChart">
        <MainChart :getChartStats="getChartStats" :main_chart="main_chart" />
      </div>

      <div class="Charts__PieChart">
        <PieChart :complaints_pie="complaints_pie" />
      </div>
    </div>

  <div class="Charts">
    <div class="Charts__MainChart" >
      <BarChart :data="main_chart.complaints_by_month"/>
    </div>
    <div>
      <GeoMap :denuncias="geo_map.complaints_geo" :getGeoStats="getGeoStats"/>
    </div>
  </div>
  </main>
</template>

<script>
import CardStats from "../widgets/Statistics/CardStats.vue";
import MainChart from "../widgets/Statistics/MainChart.vue";
import PieChart from "../widgets/Statistics/PieChart.vue";
import BarChart from "../widgets/Statistics/BarChart.vue";
import GeoMap from "../widgets/Statistics/GeoMap.vue";
import moment from "moment";

import {
  companyStatisticsCards,
  companyStatisticsGraphs,
  companyStatisticsGeo,
  exportComplaints
} from "../../api/stats";
export default {
  components: {
    CardStats,
    MainChart,
    PieChart,
    BarChart,
    GeoMap
  },
  data() {
    return {
      cardstats: {},
      alerts_last: {},
      complaints_last: {},
      most_used_complaint_last: {},
      users_last: {},

      complaints_pie: {},
      main_chart: {},
      geo_map: [],
      start_date: null,
      end_date: null,
    };
  },
  methods: {
    getCardStats() {
      companyStatisticsCards().then((res) => {
        this.cardstats = res;
        this.alerts_last = res.alerts_last;
        this.complaints_last = res.complaints_last;
        this.most_used_complaint_last = res.most_used_complaint_last;
        this.users_last = res.users_last;
      });
    },
    getChartStats(type) {
      const data = { type };
      companyStatisticsGraphs(data).then((res) => {
        this.complaints_pie = res.complaints_pie;
        this.main_chart = res
      });
    },
    getGeoStats(data) {
      companyStatisticsGeo(data).then((res) => {
        this.geo_map = res;
      });
    },
    getExcel() {
      const data = {start_date: moment(this.start_date).format("YYYY-MM-DD"), end_date: moment(this.end_date).format("YYYY-MM-DD")}
      exportComplaints(data).then(res => {
        window.open(res.url, '_blank').focus();
      })
    }
  },
  mounted() {
    this.getCardStats();
    this.getChartStats();
    this.getGeoStats();
  },
};
</script>

<style lang="scss" scoped>
.Cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}
@media (min-width: 576px) {
  .Cards {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
@media (min-width: 950px) {
  .Cards {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
}

.Charts {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  height: 800px;
  min-height: 700px;

  &__PieChart {
    padding-top: 40px;
    box-shadow: 2px 0px 39px -12px rgba(0,0,0,0.52);
    height: 97%;
  }
}
@media (min-width: 576px) {
  .Charts {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    height: 1100px;
    min-height: 450px;
  }
}
@media (min-width: 950px) {
  .Charts {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    height: 350px;
  min-height: 450px;
  }
}
.datepickers{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    @media (max-width: 576px) {
        .datepickers{
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px auto;
        }
    }
</style>