var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"datepickers"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('label',{staticStyle:{"margin-bottom":"0","font-weight":"600"}},[_vm._v("Desde: ")]),_c('datetime',{staticClass:"my-2 mx-4 datepickers__datepicker",attrs:{"width":"100%","type":"date","value-zone":Intl.DateTimeFormat().resolvedOptions().timeZone},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('label',{staticStyle:{"margin-bottom":"0","font-weight":"600"}},[_vm._v("Hasta:")]),_c('datetime',{staticClass:"my-2 mx-4 datepickers__datepicker",attrs:{"width":"100%","type":"date","value-zone":Intl.DateTimeFormat().resolvedOptions().timeZone},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1),_c('CButton',{staticClass:"mr-0",attrs:{"color":"success","square":"","disabled":!_vm.start_date || !_vm.end_date,"size":"sm"},on:{"click":function($event){return _vm.getMap()}}},[_vm._v(" Obtener Mapa ")])],1),_c('div',{staticClass:"w-100 p-0 map-container-dashboard",staticStyle:{"position":"relative"}},[_c('GmapMap',{ref:"map",staticClass:"map-container",style:('height: ' + _vm.containerHeight + 'px'),attrs:{"center":_vm.$store.state.mapCenter.center,"zoom":_vm.$store.state.mapCenter.zoom,"options":{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      }}},[(_vm.showHeatMap)?_c('GmapHeatmap',{attrs:{"data":_vm.heatData,"options":{ dissipating: false }}}):_vm._e(),_vm._l((_vm.denuncias),function(m,index){return _c('GmapMarker',{key:index,ref:index,refInFor:true,attrs:{"position":{
          lat: Number(m.location.latitude),
          lng: Number(m.location.longitude),
        },"clickable":true,"icon":{
          url: _vm.returnIcon(m),
          scaledSize: { width: 40, height: 68 },
          markerInfo: { ...m },
        },"title":m.type && m.type.title}})})],2),_c('div',{staticStyle:{"position":"absolute","top":"30px","left":"30px","z-index":"99"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Acercar Mapa'),expression:"'Acercar Mapa'"}],staticClass:"mx-1",attrs:{"color":"success"},on:{"click":function($event){return _vm.changeZoom()}}},[_c('font-awesome-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon":"plus"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Alejar Mapa'),expression:"'Alejar Mapa'"}],staticClass:"mx-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeZoom(1)}}},[_c('font-awesome-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon":"minus"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Ver mapa calor alertas'),expression:"'Ver mapa calor alertas'"}],staticClass:"mx-1",attrs:{"color":_vm.showHeatMap ? 'success' : 'primary'},on:{"click":function($event){return _vm.toggleHeatMap()}}},[(_vm.loadingHeatMap)?_c('CSpinner',{staticStyle:{"width":"20px","height":"20px"},attrs:{"color":"white"}}):_c('font-awesome-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon":"fire-alt"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }